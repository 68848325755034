






































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import FilterBox from "../filter-box.vue";
import { GetJianchaList } from "@/request/datacenter";
@Component({
  components: {
    FilterBox,
  },
})
export default class Name extends mixins(listMixin) {
  @Prop()
  private id: any; // 门诊或住院id
  @Prop()
  private parent: any; // 门诊或住院，拿关联的数据
  @Prop({ type: Boolean, default: false })
  private hideFilter: any;
  private data: any = [];
  private filterChange(e: any) {
    this.filters = JSON.parse(JSON.stringify(e));
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private goRead(item: any) {
    this.$emit("goDetail", item);
  }
  getList() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      search: this.filters.search,
      科室名称: this.filters["科室"],
      current_page: this.filters.current_page,
      page_num: this.filters.page_count,
      patient_id: this.patient.patient_id,
      start_time: this.filters.startTime,
      end_time: this.filters.endTime,
    };
    if (this.id) {
      params.h_o_id = this.id;
    }
    GetJianchaList(this, params, loading).then((res: any) => {
      this.data = res.data;
      this.filters.total_count = res.total_count;
      if (this.data.length > 0) {
        this.ifShowEmpty = false;
      } else {
        this.ifShowEmpty = true;
      }
    });
  }
  mounted() {
    this.getList();
  }
}
