import { render, staticRenderFns } from "./menzhen.vue?vue&type=template&id=3881df84&scoped=true&"
import script from "./menzhen.vue?vue&type=script&lang=ts&"
export * from "./menzhen.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3881df84",
  null
  
)

export default component.exports