



































import { Component, Vue, Prop } from "vue-property-decorator";
import { GetJianchaDetail } from "@/request/datacenter";
@Component({})
export default class Name extends Vue {
  @Prop()
  private selectData: any;
  private detail: any = {};
  private goList() {
    this.$emit("goList");
  }
  private getDetail() {
    if (!this.selectData.id) {
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        id: this.selectData.id,
      },
    };
    GetJianchaDetail(this, params, loading).then((res: any) => {
      this.detail = res;
    });
  }
  private mounted() {
    this.getDetail();
  }
}
