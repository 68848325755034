

















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Jianyan from "../jianyan/list.vue";
import Jiancha from "../jiancha/list.vue";
import Chufang from "../chufang/list.vue";
import { GetMenzhenDetail } from "@/request/datacenter";
import JianyanList from "../jianyan/list.vue";
import JianyanDetail from "../jianyan/detail.vue";
import JianchaList from "../jiancha/list.vue";
import JianchaDetail from "../jiancha/detail.vue";
@Component({
  components: {
    Jianyan,
    Jiancha,
    Chufang,
    JianyanList,
    JianyanDetail,
    JianchaList,
    JianchaDetail,
  },
})
export default class Name extends Vue {
  @Prop()
  private selectData: any;
  private type: any = "详情";
  private detail: any = {};
  private detailType: any = "list";
  private detailSelectData: any;
  private changeTab(val: any) {
    this.type = val;
    this.detailType = "list";
  }
  private returnList() {
    this.changeTab("详情");
    this.$emit("goList");
  }
  private getDetail() {
    if (!this.selectData || !this.selectData.id) {
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        id: this.selectData.id,
      },
    };
    GetMenzhenDetail(this, params, loading).then((res: any) => {
      this.detail = res;
    });
  }
  private goList() {
    this.detailType = "list";
  }
  private goDetail(item: any) {
    this.detailType = "detail";
    this.detailSelectData = item;
  }
  private mounted() {
    this.type = "详情";
    this.getDetail();
  }
}
